import React from "react";
import Layout from "../layout";
import Header from "../header";
// import data from "../projects.data";
import "../../scss/categorypage.scss";
import { Link } from "gatsby";
import SEO from "../seo";

const data = [
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/ratnadeepinstitute/hex.png",
    alt: "",
    projectname: "Institutions",
    link: "/projects/institutions/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/investmentcorporationchennai/pic2.png",
    alt: "",
    projectname: "Commercial",
    link: "/projects/commercial/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/atravilla/1.png",
    alt: "",
    projectname: "Residential",
    link: "/projects/residential/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/showflat/1.png",
    alt: "",
    projectname: "Interiors",
    link: "/projects/interiors/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/sgtuniversity/hex.png",
    alt: "",
    projectname: "Master Planning",
    link: "/projects/masterplanning/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/itcpehalgam/pic1.png",
    alt: "",
    projectname: "Landscape",
    link: "/projects/landscape/",
  },
];

export default function ProjectIndex() {
  function Hex({ imgSrc, alt = "Project Hex", projectname = "", link = "" }) {
    return (
      <li class="hex">
        <div class="hexIn">
          <Link class="hexLink" to={link}>
            <img src={imgSrc} alt={alt} />
            <div className="overlay">
              <h3 className="overlay__text">{projectname}</h3>
            </div>
          </Link>
        </div>
      </li>
    );
  }

  const allhexgons = data.map((hex, index) => (
    <Hex
      imgSrc={hex.imgSrc}
      alt={"Project Hex"}
      projectname={hex.projectname}
      link={hex.link}
    />
  ));

  return (
    <Layout>
      <Header />
      <SEO
        title="A&D Studio | Explore Our Award-Winning Projects"
        description="View details and showcases of pictures of our specialized developed projects across industries and functions."
        img="https://archdesignsstudio.com/assets/linkpreviews/A&D_linkpreview-07.jpg"
      />
      <div className="categorypage">
        <img
          className="categorypage__background"
          alt="Background"
          src="https://images.pexels.com/photos/443378/pexels-photo-443378.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
        />
        <div className="categorypage__overlay"></div>
        <div className="categorypage__content">
          <h1 className="categorypage__heading">
            {" "}
            <Link className="categorypage__back" to={"/"}>
              &#8592;
            </Link>
            Signature Projects
          </h1>

          <ul id="hexGrid">{allhexgons}</ul>
        </div>
      </div>
    </Layout>
  );
}
